import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

export const updateMutation = (mutateFunction: Function, queryKey: string): any => {
  const queryClient = useQueryClient();
  return useMutation(mutateFunction as MutationFunction, {
    onSuccess: (data: any) => {
      queryClient.setQueryData(queryKey, (old: any) => {
        return old.map((item: any) => (item.id === data?.id ? data : item));
      });
      queryClient.invalidateQueries([queryKey]);
      toast.success(`Updated ${queryKey}`);
    },
    onError: () => {
      toast.error(`Could not update ${queryKey}`);
    },
  });
};

export const removeMutation = (mutateFunction: Function, queryKey: string): any => {
  const queryClient = useQueryClient();
  const lowerCaseQueryKey = queryKey.toLowerCase();
  return useMutation(mutateFunction as MutationFunction, {
    onSuccess: (data: any, variables) => {
      queryClient.invalidateQueries(queryKey);
      data &&
        queryClient.setQueryData(queryKey, (old: any) => ({
          ...old,
          data: [old[lowerCaseQueryKey].filter((item: any) => item.id !== variables)],
        }));
      toast.success(`Removed ${queryKey}`);
    },
    onError: () => {
      toast.error(`Could not delete ${queryKey}`);
    },
  });
};

export const appendMutation = (mutateFunction: Function, queryKey: string): any => {
  const queryClient = useQueryClient();
  return useMutation(mutateFunction as MutationFunction, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(queryKey);
      queryClient.setQueryData(queryKey, (old: any) => [...old, data]);
      toast.success(`Updated ${queryKey}`);
    },
    onError: () => {
      toast.error(`Could not update ${queryKey}`);
    },
  });
};
