import createContext from 'constate';
import { User } from '../../types';

interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const [UserProvider, useUser] = createContext(({ user, setUser }: UserContextProps) => {
  return { user, setUser };
});

export { UserProvider, useUser };
