import React, { Suspense, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { getOrganization } from './api/api';
import { restoreSession } from './api/authentication';
import DashboardProviders from './state/DashboardProviders';
import { UserProvider, useUser } from './state/context/UserContext';
import { Organization, User } from './types';
import Loading from './ui/components/Loading';
import ResetComponent from './ui/components/ResetComponent';
import Admin from './ui/pages/Admin';
import { OrganizationProvider } from './state/context/OrganizationContext';

const queryClient = new QueryClient();
export default function App() {
  const [user, setUser] = useState<User | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const initialize = async () => {
    try {
      const loggedInUser = await restoreSession();
      if (!loggedInUser) return;
      setUser(loggedInUser);
      const org = await getOrganization();
      if (!org) return;
      setOrganization(org);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) return;
    setInitialized(true);
    initialize();
  }, [initialized]);

  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <div className="App">
        <UserProvider user={user} setUser={setUser}>
          <OrganizationProvider organization={organization} setOrganization={setOrganization}>
            {isLoading ? <Loading /> : <AppRoutes />}
            <Toaster
              toastOptions={{
                style: {
                  color: '#FFFFFF',
                  background: '#000000',
                  border: 'none',
                  borderRadius: '0px',
                  boxShadow: 'none',
                },
                success: {
                  iconTheme: {
                    primary: '#b6ff9e',
                    secondary: '#000000',
                  },
                },
                error: {
                  iconTheme: {
                    primary: '#F05151',
                    secondary: '#000000',
                  },
                },
              }}
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </OrganizationProvider>
        </UserProvider>
      </div>
    </QueryClientProvider>
  );
}

function AppRoutes() {
  const Dashboard = React.lazy(() => import('./ui/pages/Dashboard'));
  const Login = React.lazy(() => import('./ui/pages/Login'));
  const { user } = useUser();
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetComponent />} />
          <Route
            path="/dashboard"
            element={
              <DashboardProviders>
                <Dashboard />
              </DashboardProviders>
            }
          />
          <Route
            path="/"
            element={
              user ? (
                <DashboardProviders>
                  <Dashboard />
                </DashboardProviders>
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </Router>
    </Suspense>
  );
}
