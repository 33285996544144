interface AdminNavbarProps {
  currentPage: string;
  setCurrentPage: Function;
}

export default function AdminNavbar({ currentPage, setCurrentPage }: AdminNavbarProps) {
  return (
    <div className="flex justify-start w-full h-16 bg-black">
      <div className="navbar-buttons">
        <button
          type="button"
          className={currentPage === 'users' ? 'selected' : ''}
          onClick={() => setCurrentPage('users')}
        >
          Users
        </button>
        <button
          type="button"
          className={currentPage === 'projects' ? 'selected' : ''}
          onClick={() => setCurrentPage('projects')}
        >
          Projects
        </button>
        <button
          type="button"
          className={currentPage === 'economy' ? 'selected' : ''}
          onClick={() => setCurrentPage('economy')}
        >
          Economy
        </button>
        <button
          type="button"
          className={currentPage === 'workerSentiment' ? 'selected' : ''}
          onClick={() => setCurrentPage('workerSentiment')}
        >
          WorkerSentiment
        </button>
        <button
          type="button"
          className={currentPage === 'organizations' ? 'selected' : ''}
          onClick={() => setCurrentPage('organizations')}
        >
          Organizations
        </button>
      </div>
    </div>
  );
}
