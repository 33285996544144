import createContext from 'constate';
import { useState } from 'react';
import { Project } from '../../types';

const [ProjectsProvider, useProjects] = createContext(() => {
  const [defaultProjects, setDefaultProjects] = useState<Array<Project>>([]);
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [projectsShown, setProjectsShown] = useState<Array<Project>>([]);

  return {
    projects,
    setProjects,
    projectsShown,
    setProjectsShown,
    defaultProjects,
    setDefaultProjects,
  };
});

export { ProjectsProvider, useProjects };
