/**
 * The following are names of projects that should be the default package for any user
 * TODO: Automatically assign these projects to worker
 */
export const doctorsReportProjectName = 'Sykemelding';

export const welfareLeaveProjectName = 'Velferdsperm';

export const welfareLeaveSicknessProjectName = 'Sykdom velferdsperm';

export const educationDayProjectName = 'Fagdag';

export const internalProjectName = 'Interntid';
// Only English project name. Consider changing to Norwegian?
export const vacationProjectName = 'Vacation';

export const vacationTransferProjectName = 'Vacation Transfer';
// Will probably be changed in production to 'Egenmelding'
export const selfReportProjectName = 'Egenmelding';

export const officeLunchProjectName = 'Kontorlunsj';
export const officeLunchProjectRef = 100;

export const internalOvertimeProjectName = 'Interntid OVERTIME';

// Adding more hours than this number on a single day will trigger a GUI warning
export const hourWarningLimit = 10;

export const selfReportDaysLimit = 12;
// The resolution of increments allowed (TODO: Fetch from backend config metadata endpoint)
export const hourStepIncrement = 0.25;
// Default number of vacation days in a year (5 weeks)
export const defaultVacationDays = 25;

export const defaultProjectsArray: Array<string> = [
  doctorsReportProjectName,
  welfareLeaveProjectName,
  welfareLeaveSicknessProjectName,
  educationDayProjectName,
  internalProjectName,
  vacationProjectName,
  vacationTransferProjectName,
  selfReportProjectName,
  officeLunchProjectName,
];
