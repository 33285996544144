import {
  FillEntry,
  MetadataDao,
  Organization,
  Projects,
  WorkerSentiment,
  WorkerSentiments,
  Workhour,
  Workhours,
  Workmonth,
  Workmonths,
  Workyear,
} from '../types';
import {
  deserializeWorkhour,
  deserializeWorkhours,
  getFirstDayOfMonth,
  mapValuesToWorkersentiment,
} from './apiUtils';

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const handleResponse = async (
  response: Response,
  errorText: string,
  deserializeFunction?: Function
): Promise<any> => {
  const data = await response.json();

  if (response.ok) {
    if (data === 'bs' || data === 'no') {
      return Promise.reject(new Error(errorText));
    }
    if (deserializeFunction !== undefined) {
      return deserializeFunction(data);
    }
    return data;
  }
  return Promise.reject(new Error(errorText));
};

//gets the current workers organization
export const getOrganization = async (): Promise<Organization> => {
  const response = await fetch(`${baseUrl}/organization`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get organization');
};

export const getOrganizations = async (): Promise<Organization[]> => {
  const response = await fetch(`${baseUrl}/organizations`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get organizations');
};

export const getProjects = async (): Promise<Projects> => {
  const response = await fetch(`${baseUrl}/projects`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get projects');
};

export const getWorkMonth = async (offset: number): Promise<Workmonths> => {
  const startOfMonth = getFirstDayOfMonth(offset);

  const response = await fetch(`${baseUrl}/workmonths?filter%5Bstartofmonth%5D=${startOfMonth}`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get projects');
};

export const getWorkYear = async (year: number): Promise<Workyear> => {
  const response = await fetch(`${baseUrl}/workyears?filter%5Byear%5D=${year}`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get workyears');
};

// Get workhour step-increment from backend
export const getMetadata = async (): Promise<MetadataDao> => {
  const response = await fetch(`${baseUrl}/metadata`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not fetch metadata');
};

export const createInitialWorkMonth = async (offset: number): Promise<Workmonths> => {
  const startOfMonth = getFirstDayOfMonth(offset);
  const content = {
    workmonth: {
      worker: 0,
      startofmonth: startOfMonth,
      delivered: 0,
      billingRate: 0,
      accountedHoursSoFar: 0,
      accountedHoursTotal: 0,
      accountableHoursSoFar: 0,
      accountableHoursTotal: 0,
    },
  };

  const response = await fetch(`${baseUrl}/workmonths?filter%5Bstartofmonth%5D=${startOfMonth}`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not create workmonth');
};

export const getWorkHoursForMonth = async (monthId: number): Promise<Workhours> => {
  const response = await fetch(`${baseUrl}/workhours?filter%5Bworkmonthref%5D%5B%5D=${monthId}`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get work hours for month', deserializeWorkhours);
};

export const postWorkhour = async (entry: Workhour): Promise<Workhour> => {
  const workhour = {
    workhour: {
      id: entry.id,
      date: entry.date.format('YYYY-MM-DD'),
      hours: entry.hours,
      overtime: entry.overtime,
      taskC: '',
      commentC: entry.comment,
      officeLunch: entry.officeLunch,
      projectref: entry.projectref,
      workweekref: null,
      workmonthref: entry.workmonthref.toString(),
    },
  };
  const response = await fetch(`${baseUrl}/workhours`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(workhour),
  });
  return handleResponse(response, 'Could not post work hours', deserializeWorkhour);
};

export const postWorkerSentiment = async (
  score: number,
  date: string
): Promise<WorkerSentiment> => {
  const content = mapValuesToWorkersentiment(score, date);

  const response = await fetch(`${baseUrl}/sentiment`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not post workersentiment');
};

export const getWorkerSentiment = async (): Promise<WorkerSentiments> => {
  const response = await fetch(`${baseUrl}/sentiment`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get worker sentiment');
};

export const getWorkerSentimentWithFilter = async (
  attribute: string,
  value: string
): Promise<WorkerSentiment> => {
  const response = await fetch(`${baseUrl}/workersentiment/filter%5B${attribute}%5D=${value}`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get worker sentiment');
};

export const editWorkHour = async (entry: Workhour): Promise<void> => {
  const workhour = {
    workhour: {
      id: entry.id,
      date: entry.date.format('YYYY-MM-DD'),
      hours: entry.hours,
      overtime: entry.overtime,
      taskC: '',
      commentC: entry.comment,
      officeLunch: entry.officeLunch,
      projectref: entry.projectref,
      workweekref: null,
      workmonthref: entry.workmonthref.toString(),
    },
  };
  const response = await fetch(`${baseUrl}/workhours/${entry.id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(workhour),
  });

  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Could not update work hour'));
};

export const deleteWorkhour = async (id: number): Promise<void> => {
  const response = await fetch(`${baseUrl}/workhours/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Could not delete work hour'));
};

export const deliverWorkmonth = async (workmonth: Workmonth): Promise<Workmonth> => {
  const content = { workmonth };

  const response = await fetch(`${baseUrl}/workmonths/${workmonth.id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not deliver /un-deliver workmonth');
};

export const fillHours = async ({
  projectRef,
  hours,
  workmonthRef,
  to,
  from,
}: FillEntry): Promise<Array<Workmonth>> => {
  let content;
  if (from !== undefined && to !== undefined) {
    content = {
      projectref: projectRef,
      hours,
      from,
      to,
    };
  } else {
    content = { projectref: projectRef, hours };
  }

  const response = await fetch(`${baseUrl}/workmonths/${workmonthRef}/fill`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });

  return handleResponse(response, 'Could not fill workhours');
};

export const resetPassword = async (password: string): Promise<any> => {
  const content = { password };

  const response = await fetch(`${baseUrl}/workers/password`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not reset password');
};

export const getHolidaysForYear = async (year: number): Promise<string[]> => {
  const response = await fetch(`${baseUrl}/holidays?filter%5Byear%5D=${year}-01-01`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get work hours for month');
};
