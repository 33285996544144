import createContext from 'constate';
import { useState } from 'react';
import { Workyear } from '../../types';

const [WorkyearProvider, useWorkyear] = createContext(() => {
  const [workyear, setWorkyear] = useState<Workyear | null>(null);
  return { workyear, setWorkyear };
});

export { WorkyearProvider, useWorkyear };
