import dayjs from 'dayjs';
import { Project, Projects, Workhours, Workhour, WorkerSentimentWrapped } from '../types';

export const deserializeWorkhours = (workhours: Workhour[]): Workhours => {
  return workhours.map(deserializeWorkhour);
};

export const deserializeWorkhour = (workhour: Workhour): Workhour => {
  return {
    ...workhour,
    date: dayjs(workhour.date),
    comment: workhour.commentC,
    workmonthref: workhour.workmonthref,
    projectref: workhour.projectref,
  };
};

export const getFirstDayOfMonth = (offset: number): string =>
  dayjs().startOf('month').add(offset, 'month').format('YYYY-MM-DD');

export const getFirstDayOfMonthYear = (year: number, month: number): string =>
  dayjs().set('year', year).set('month', month).startOf('month').format('YYYY-MM-DD');

export const mapProjectRefToName = (projectRef: number, projects: Projects): string => {
  try {
    return projects.filter((project) => project.id === projectRef)[0].name;
  } catch (e) {
    return '';
  }
};

export const mapProjectNameToRef = (name: string, projects: Projects): number => {
  try {
    return projects.filter((project) => project.name === name)[0].id;
  } catch (e) {
    return -1;
  }
};

export const mapProjectNameToProject = (name: string, projects: Projects): Project | undefined => {
  try {
    return projects.filter((project) => project.name === name)[0];
  } catch (e) {
    return undefined;
  }
};

// Need to map values to WorkerSentimentPostDao as ID is set in DB.
// Thus cannot utilize the GET WorkerSentiment type (where ID is explicitly requiered)
export const mapValuesToWorkersentiment = (
  score: number,
  date: string
): WorkerSentimentWrapped => ({
  workersentiment: {
    score,
    date,
  },
});
