import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../api/api';
import { invalidateCookie } from '../../api/authentication';
import { useUser } from '../../state/context/UserContext';

export default function ResetComponent() {
  const [password, setPassword] = useState('');
  const { setUser } = useUser();
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleButtonClick = (): void => {
    if (password !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }
    resetPassword(password).then(() => {
      invalidateCookie();
      setUser(null);
      navigate('/login');
    });
  };

  return (
    <div className="grid place-content-center h-screen">
      <h1 className="m-0 p-0">Reset password</h1>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.currentTarget.value)}
        className="input border border-black"
        placeholder="New password"
      />
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        className="input border border-black"
        placeholder="Repeat password"
      />
      <button type="button" className="button w-52" onClick={handleButtonClick}>
        Change password
      </button>
    </div>
  );
}
