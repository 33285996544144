import { User } from '../types';
import { baseUrl, handleResponse } from './api';

export const getAccessToken = async (email: string, password: string): Promise<User> => {
  const params = new URLSearchParams({
    email,
    password,
  });
  const response = await fetch(`${baseUrl}/request-session?${params}`, {
    method: 'GET',
  });

  const data = await response.json();

  if (response.ok) {
    if (data === 'bs' || data === 'no') {
      return Promise.reject(new Error('Wrong username or password'));
    }
    return data;
  }
  return Promise.reject(new Error('Could not get access token'));
};

export const postForgotPassword = async (email: string) => {
  const content = {
    email: email,
  };

  const response = await fetch(`${baseUrl}/forgot`, {
    method: 'POST',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });

  return handleResponse(response, 'Could not ask server for reset password link');
};

export const getAccessTokenByPostRequest = async (
  email: string,
  password: string
): Promise<User> => {
  const content = {
    sessiondata: {
      email: email,
      password: password,
    },
  };

  const response = await fetch(`${baseUrl}/request-session`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });

  return handleResponse(response, 'Could not get access token');
};

// Cookie with session-data (JWT token) is added to request header when user is logged in
export const restoreSession = async (): Promise<User | null> => {
  if (!hasCookie()) {
    return null;
  }
  const response = await fetch(`${baseUrl}/restore-session`, {
    method: 'GET',
    credentials: 'include',
  });

  const data = await response.json();

  if (response.ok) {
    if (data === 'bs' || data === 'no') {
      return Promise.reject(new Error('Could not restore session'));
    }
    return data;
  }
  return Promise.reject(new Error('Could not restore session'));
};

export const invalidateCookie = (): void => {
  document.cookie = '_sessiondata=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict';
};

export const hasCookie = (): boolean => {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith('_sessiondata'));
  if (cookie) {
    return true;
  }
  return false;
};
