import { ReactNode, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllProjects, getAllWorkers } from '../../../api/admin';
import { getOrganizations } from '../../../api/api';
import { Organization, Project, Projects, Worker, Workers } from '../../../types';
import AdminCreateWorkerProjectOrganizationField from './AdminCreateWorkerProjectOrganizationField';

interface AdminMobileListProps {
  page: string;
  setWorker: Function;
  setProject: Function;
  setOrganization: Function;
}

export default function AdminMobileList({
  page,
  setWorker,
  setProject,
  setOrganization,
}: AdminMobileListProps) {
  const projects = useQuery<Projects>('projects', getAllProjects);
  const workers = useQuery<Workers, unknown>('workers', getAllWorkers);
  const organizations = useQuery<Organization[]>('organizations', getOrganizations);
  const [showAdd, setShowAdd] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const isWorker = page === 'users';
  const isProject = page === 'projects';

  const handleWorkerClick = (worker: Worker) => () => {
    setWorker(worker);
    setShowAll(false);
  };

  const handleProjectClick = (project: Project) => () => {
    setProject(project);
    setShowAll(false);
  };

  const handleOrganizationClick = (organization: Organization) => () => {
    setOrganization(organization);
    setShowAll(false);
  };

  const handleOnSave = () => {
    projects.refetch();
    workers.refetch();
  };

  const renderList = (): ReactNode => {
    if (isWorker) {
      return (
        workers.data
          ?.sort((a, b) => (a.firstname > b.firstname ? 1 : -1))
          .map((w) => (
            <button
              className="workerlist-button"
              type="button"
              onClick={handleWorkerClick(w)}
              key={w.id}
            >
              {`${w.firstname} ${w.lastname}`}
            </button>
          )) || []
      );
    } else if (isProject) {
      return (
        projects.data
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((project) => (
            <button
              className="workerlist-button"
              type="button"
              key={project.id}
              onClick={handleProjectClick(project)}
            >
              {`${project.name}`}
            </button>
          )) || []
      );
    } else {
      return (
        organizations.data
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((organization) => (
            <button
              className="workerlist-button"
              type="button"
              key={organization.id}
              onClick={handleOrganizationClick(organization)}
            >
              {`${organization.name}`}
            </button>
          )) || []
      );
    }
  };

  const getShowAllText = (): string => {
    if (showAll) {
      return 'Hide';
    }
    if (isWorker) {
      return 'Show Employees';
    }
    return 'Show Projects';
  };

  if (workers.isLoading || projects.isLoading || organizations.isLoading) {
    return <p>Loading...</p>;
  }

  if (workers.error || projects.error || organizations.error) {
    return <p>Something went wrong.</p>;
  }

  return (
    <div className="w-full">
      <button
        type="button"
        className="focus workerlist-button add-button"
        onClick={() => setShowAdd(true)}
      >
        {isWorker ? 'Add Employee' : isProject ? 'Add Project' : 'Add Organization'}
      </button>
      <button
        type="button"
        className="workerlist-button add-button"
        onClick={() => setShowAll(!showAll)}
      >
        {getShowAllText()}
      </button>
      {showAll && renderList()}
      <AdminCreateWorkerProjectOrganizationField
        show={showAdd}
        setShow={setShowAdd}
        typeOfField={page === 'user' ? 'worker' : page}
        onSave={handleOnSave}
      />
    </div>
  );
}
