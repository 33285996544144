import dayjs from 'dayjs';
import { mapProjectRefToName } from '../api/apiUtils';
import { officeLunchProjectRef, selfReportProjectName, vacationProjectName } from '../config';
import { Project, Projects, SelectedWeek, Workhour } from '../types';
import { getAllEntriesForSingleDayOfMonth } from './workhour.utils';
import { getWorkHoursForMonth } from '../api/api';
import { getWorkMonth } from '../api/api';

export const overtimeProjectNameEnding = ' OVERTIME';

export const projectsNotShown = (
  projects: Array<Project>,
  projectsShown: Array<Project>
): Array<Project> =>
  projects
    .filter((p) => !projectsShown.map((ps) => ps.id).includes(p.id))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

export const hoursInProjectRegistered = (id: number, entries: Array<Workhour>): boolean =>
  entries.map((entry) => entry.projectref).includes(id);

export const sicknessRegisteredOnThisDay = (
  date: Date,
  entries: Array<Workhour>,
  projects: Projects
): boolean =>
  entries.some(
    (entry) =>
      mapProjectRefToName(entry.projectref, projects) === selfReportProjectName &&
      entry.date.date() === date.getDate()
  );

export const vacationRegisteredOnThisDay = (
  date: Date,
  entries: Array<Workhour>,
  projects: Projects
): boolean =>
  entries.some(
    (entry) =>
      mapProjectRefToName(entry.projectref, projects) === vacationProjectName &&
      entry.date.date() === date.getDate()
  );

export const filterDatesOutsideMonth = (selectedWeek: SelectedWeek): SelectedWeek => {
  const dates = selectedWeek.selectedDates.filter(
    (date) => dayjs(date.date).format('MMMM') === selectedWeek.selectedMonth
  );
  const swCopy = { ...selectedWeek };
  swCopy.selectedDates = [...dates];
  return swCopy;
};

export const getTotalHoursPerProjectPerWeek = (
  project: Project,
  projects: Project[],
  selectedWeek: SelectedWeek,
  interEntries: Array<Workhour>,
  includeOvertime = false
): string =>
  interEntries
    .filter(
      (entry) =>
        entry.overtime === includeOvertime &&
        (includeOvertime
          ? project.name.includes(mapProjectRefToName(entry.projectref, projects))
          : entry.projectref === project.id) &&
        filterDatesOutsideMonth(selectedWeek)
          .selectedDates.map((selectedDate) => selectedDate.date.getDate())
          .includes(entry.date.date())
    )
    .reduce((prev, current) => prev + current.hours, 0)
    .toString();

export const getTotalOfficeLunchesPerWeek = (
  selectedWeek: SelectedWeek,
  interEntries: Array<Workhour>,
  holidays: string[]
): string => {
  return `${
    interEntries
      .filter((entry) => entry.projectref === officeLunchProjectRef)
      .filter((entry) =>
        filterDatesOutsideMonth(selectedWeek)
          .selectedDates.map((selectedDate) => selectedDate.date.getDate())
          .includes(entry.date.date())
      ).length
  }/${
    filterDatesOutsideMonth(selectedWeek)
      .selectedDates.filter((date) => !holidays?.includes(dayjs(date.date).format('YYYY-MM-DD')))
      .filter((date) => date.date.getDay() !== 0 && date.date.getDay() !== 6).length
  }`;
};

const projectNameIsIllnessBased = (projectName: string): boolean => {
  const illnessProjectNames = [selfReportProjectName, vacationProjectName];
  return illnessProjectNames.includes(projectName);
};

export const validateNonConflictingProjectsByEntry = (
  entry: Workhour,
  selectedWeek: SelectedWeek,
  entries: Array<Workhour>,
  projects: Projects
): boolean => {
  let hackConflictingProjectsCheck = true;
  selectedWeek.selectedDates.forEach((x) => {
    if (x.date.getDate() === entry.date.date()) {
      const dayEntries = getAllEntriesForSingleDayOfMonth(entry.date.date(), entries);
      // If the given project name is in the illness category, all other project names should be as well
      // This check could also be changed to simply asserting that no other projects exists on the given day
      if (projectNameIsIllnessBased(mapProjectRefToName(entry.projectref, projects))) {
        dayEntries.forEach((dayEntry) => {
          if (!projectNameIsIllnessBased(mapProjectRefToName(dayEntry.projectref, projects))) {
            hackConflictingProjectsCheck = false;
          }
        });
        // If the given project name is NOT in the illness category, no other projects should be either
      } else {
        dayEntries.forEach((dayEntry) => {
          if (projectNameIsIllnessBased(mapProjectRefToName(dayEntry.projectref, projects))) {
            hackConflictingProjectsCheck = false;
          }
        });
      }
    }
  });
  return hackConflictingProjectsCheck;
};

export const moveProjectToFront = (
  projectName: string,
  projects: Array<Project>
): Array<Project> => {
  const projectsWithoutProject = projects.filter((p) => p.name !== projectName);
  const wantedProject = projects.find((p) => p.name === projectName);
  if (!wantedProject) return projects;
  return [{ ...wantedProject }, ...projectsWithoutProject];
};

export const addOvertimeProjects = (
  projects: Array<Project>,
  overtimeProjectNames: Array<string>
): Array<Project> =>
  projects.flatMap((p) =>
    overtimeProjectNames.includes(p.name)
      ? [p, { ...p, name: p.name + overtimeProjectNameEnding }]
      : [p]
  );

export const removeOvertimeProjects = (projects: Array<Project>): Array<Project> =>
  projects.filter((p) => !isOvertimeProjectName(p.name));

export const isOvertimeProjectName = (projectName: string): boolean =>
  projectName.endsWith(overtimeProjectNameEnding);

export const stripOvertimeProjectName = (projectName: string): string => {
  const i = projectName.indexOf(overtimeProjectNameEnding);
  return -1 === i ? projectName : projectName.substring(0, i);
};

export const getPrevMonthProjectIDs = async (): Promise<number[]> => {
  const projectRefArray: number[] = [];
  await getWorkMonth(-1)
    .then((result) =>
      0 === result.length ? Promise.resolve([]) : getWorkHoursForMonth(result[0].id)
    )
    .then((projectArray) =>
      projectArray.map((project) => projectRefArray.push(project.projectref))
    );
  const uniqueProjectRefs = Array.from(new Set(projectRefArray));

  return uniqueProjectRefs;
};

export const removeOldProjects = (
  projectList: Project[],
  prevMonthProjectIDs: number[]
): Project[] => {
  return projectList.filter((project) => prevMonthProjectIDs.includes(project.id));
};

export const switchProjectEntries = async (
  oldProject: Project | undefined,
  newProject: Project | undefined,
  entries: Workhour[],
  editMultipleHours: Function
): Promise<void> => {
  const newEntries: { newEntry: Workhour; index: number }[] = [];
  if (oldProject && newProject) {
    entries.map((entry) => {
      if (entry.projectref === oldProject?.id) {
        const oldEntry: Workhour = entry;
        const newEntry: Workhour = { ...entry, projectref: newProject.id };
        newEntries.push({ newEntry: newEntry, index: entries.indexOf(oldEntry) });
      }
    });
    editMultipleHours(newEntries);
  }
};
