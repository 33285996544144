import Papa from 'papaparse';
import {
  PostWorker,
  Project,
  ProjectWorker,
  ProjectWorkers,
  ReportPostObject,
  Worker,
  Workers,
  Workhours,
} from '../types';
import { Organization, Projects, Workmonth, Workmonths, Workyear } from './../types';
import { baseUrl, handleResponse } from './api';
import { getFirstDayOfMonth, getFirstDayOfMonthYear } from './apiUtils';

export const getAllProjects = async (): Promise<Projects> => {
  const response = await fetch(`${baseUrl}/projects?role=2`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get all projects');
};

export const getAllNonDeliveredWorkers = async (offset: number): Promise<Array<number>> => {
  const startOfMonth = getFirstDayOfMonth(offset);

  const response = await fetch(
    `${baseUrl}/workmonths/nondelivered?filter%5Bstartofmonth%5D=${startOfMonth}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );
  return handleResponse(response, 'Could not get workers who have not delivered their hours.');
};

export const getAllWorkers = async (): Promise<Workers> => {
  const response = await fetch(`${baseUrl}/workers`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get workers');
};

export const getAllActiveWorkers = async (): Promise<Workers> => {
  const response = await fetch(`${baseUrl}/workers?filter%5Bactive%5D=1`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get workers');
};

export const getWorkYearForWorker = async (year: number, worker: number): Promise<Workyear> => {
  const response = await fetch(
    `${baseUrl}/workyears?filter%5Byear%5D=${year}&filter%5Bworker%5D=${worker}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );
  return handleResponse(response, 'Could not get workyears');
};

export const getWorkhoursWithWorkmonthref = async (workmonthref: number): Promise<Workhours> => {
  const response = await fetch(`${baseUrl}/workhours?filter%5Bworkmonthref%5D=${workmonthref}`, {
    method: 'GET',
    credentials: 'include',
  });

  return handleResponse(response, 'Could not get project workers');
};

export const getAllProjectWorkers = async (): Promise<ProjectWorkers> => {
  const response = await fetch(`${baseUrl}/projectworkers?role=2`, {
    method: 'GET',
    credentials: 'include',
  });
  return handleResponse(response, 'Could not get project workers');
};

export const updateProject = async (project: Project): Promise<Project> => {
  const content = { project };
  const response = await fetch(`${baseUrl}/projects/`, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not update project');
};

export const updateOrganization = async (organization: Organization): Promise<Organization> => {
  const content = { organization };
  const response = await fetch(`${baseUrl}/organizations/${organization.id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not update organization');
};

export const updateWorker = async (worker: Worker): Promise<Worker> => {
  const content = { worker };
  const response = await fetch(`${baseUrl}/workers/${worker.id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not update worker');
};

export const createOrganization = async (organization: { name: string }): Promise<Organization> => {
  const content = { organization };
  const response = await fetch(`${baseUrl}/organizations/`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not create organization');
};

export const createWorker = async (worker: PostWorker): Promise<Worker> => {
  const content = { worker };
  const response = await fetch(`${baseUrl}/workers/`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not create worker');
};

export const createProject = async (project: { name: string }): Promise<Project> => {
  const content = { project };
  const response = await fetch(`${baseUrl}/projects/`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not create project');
};

export const deleteProjectWorker = async (id: number): Promise<null> => {
  const response = await fetch(`${baseUrl}/projectworkers/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (response.ok) {
    return Promise.resolve(null);
  }
  return Promise.reject(new Error('Could not delete projectworker'));
};

interface IProjectWorkerVals {
  projectId: number;
  workerId: number;
}

export const addProjectToWorker = async ({
  projectId,
  workerId,
}: IProjectWorkerVals): Promise<ProjectWorker> => {
  const content = {
    projectworker: {
      project: projectId,
      worker: workerId,
      rate: 0,
    },
  };
  const response = await fetch(`${baseUrl}/projectworkers`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });

  return handleResponse(response, 'Could not add project to worker');
};

interface IProjectWorkerVals2 {
  projectId: number;
  workerId: number;
  rate: number;
  pwId: number;
}

export const updateProjectWorker = async ({
  projectId,
  workerId,
  rate,
  pwId,
}: IProjectWorkerVals2): Promise<ProjectWorker> => {
  const content = {
    projectworker: {
      project: projectId,
      worker: workerId,
      rate,
      projectWorkerId: pwId,
    },
  };

  const response = await fetch(`${baseUrl}/projectworkers/${pwId}`, {
    method: 'PUT',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });

  return handleResponse(response, 'Could not update projectworker');
};

export const workMonthByUser = async (
  year: number,
  month: number,
  workerId: number
): Promise<Workmonths> => {
  const response = await fetch(
    `${baseUrl}/workmonths?filter%5Bstartofmonth%5D=${getFirstDayOfMonthYear(year, month)}
&filter%5Bworker%5D=${workerId}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );
  return handleResponse(response, 'Could not get workmonth');
};

export const createWorkMonthByUser = async (
  year: number,
  month: number,
  workerId: number
): Promise<Workmonth> => {
  const content = {
    workmonth: {
      worker: workerId,
      startofmonth: getFirstDayOfMonthYear(year, month),
      delivered: 0,
      billingRate: 0,
      accountedHoursSoFar: 0,
      accountedHoursTotal: 0,
      accountableHoursSoFar: 0,
      accountableHoursTotal: 0,
    },
  };

  const response = await fetch(`${baseUrl}/workmonths`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify(content),
  });
  return handleResponse(response, 'Could not create workmonth');
};

// Parsing csv-file containing all rows in Workhour(id, date, dayofmonth, hours, comment_c, workmonthref, projectref)
// matching given input parameters.
// setData() as callback function. Sets type of data-state equal to Array<Workhour>
export const papaFetch = (params: ReportPostObject, callback: Function): void => {
  Papa.parse(`${baseUrl}/workhours/report`, {
    download: true,
    withCredentials: true,
    header: true,
    dynamicTyping: true,
    downloadRequestHeaders: { 'content-type': 'application/json' },
    downloadRequestBody: JSON.stringify(params),
    complete: (results) => {
      callback(results.data as Workhours);
    },
  });
};
