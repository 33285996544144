import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Organization, Projects, Workers } from '../../../types';
import { getAllActiveWorkers, getAllProjects, getAllWorkers } from '../../../api/admin';
import AdminCreateWorkerProjectOrganizationField from './AdminCreateWorkerProjectOrganizationField';
import { useUser } from '../../../state/context/UserContext';
import toast from 'react-hot-toast';
import { getOrganizations } from '../../../api/api';

interface AdminListProps {
  page: string;
  setWorker: Function;
  setProject: Function;
  setOrganization: Function;
}

export default function AdminList({
  page,
  setWorker,
  setProject,
  setOrganization,
}: AdminListProps) {
  const { user } = useUser();
  const projects = useQuery<Projects>('projects', getAllProjects, {
    initialData: [],
  });
  const workers = useQuery<Workers, unknown>('workers', getAllWorkers, {
    initialData: [],
  });
  const organizations = useQuery<Organization[]>('organizations', getOrganizations, {
    initialData: [],
  });
  const activeWorkers = useQuery<Workers, unknown>('activeworkers', getAllActiveWorkers, {
    initialData: [],
  });

  const [show, setShow] = useState(false);
  const [active, setActive] = useState<boolean>(false);
  const [currentWorkers, setCurrentWorkers] = useState<Workers>([]);
  const [currentProjects, setCurrentProjects] = useState<Projects>([]);
  const [currentOrganizations, setCurrentOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    if (active) {
      projects.data && setCurrentProjects(projects.data?.filter((project) => project.active));
      activeWorkers.data && setCurrentWorkers(activeWorkers.data);
      organizations.data &&
        setCurrentOrganizations(organizations.data?.filter((organization) => organization.active));
    } else {
      if (workers.data) {
        setCurrentWorkers(workers.data);
      }
      if (projects.data) {
        setCurrentProjects(projects.data);
      }
      if (organizations.data) {
        setCurrentOrganizations(organizations.data);
      }
    }
  }, [active]);

  useEffect(() => {
    workers.data && setCurrentWorkers(workers.data);
  }, [workers.data]);

  useEffect(() => {
    projects.data && setCurrentProjects(projects.data);
  }, [projects.data]);

  useEffect(() => {
    organizations.data && setCurrentOrganizations(organizations.data);
  }, [organizations.data]);

  if (workers.isLoading || projects.isLoading || organizations.isLoading) return <p>Loading...</p>;

  if (workers.error || projects.error || organizations.error) return <p>Something went wrong.</p>;
  const renderSwitch = (p: string): JSX.Element => {
    switch (p) {
      case 'users':
        return (
          <div className="workerlist">
            <button
              type="button"
              className="focus workerlist-button add-button"
              onClick={() => setShow(true)}
            >
              Add Employee
            </button>
            <button
              type="button"
              className="flex justify-between w-full text-left text-black border-b max-h-16"
              onClick={() => setActive(!active)}
            >
              <div
                className={`pl-5 py-5  w-full ${
                  active
                    ? 'text-white bg-black focus'
                    : 'bg-white black-black hover:bg-black hover:text-white hover:border-white hover:border-x-2 '
                }`}
              >
                Current
              </div>
              <div
                className={`pr-5 text-center py-5 w-full ${
                  active
                    ? 'bg-white black-black hover:bg-black hover:text-white hover:border-white hover:border-x-2 '
                    : 'text-white bg-black focus'
                }`}
              >
                All
              </div>
            </button>
            {currentWorkers &&
              currentWorkers
                .sort((a, b) => (a.firstname > b.firstname ? 1 : -1))
                .map((w) => (
                  <button
                    className="workerlist-button"
                    type="button"
                    onClick={() => setWorker(w)}
                    key={w.id}
                  >
                    {`${w.firstname} ${w.lastname}`}
                  </button>
                ))}
            <AdminCreateWorkerProjectOrganizationField
              show={show}
              setShow={setShow}
              typeOfField={page === 'users' ? 'workers' : page}
              onSave={workers.refetch}
            />
          </div>
        );
      case 'projects':
        return (
          <div className="workerlist">
            <button
              type="button"
              className="focus workerlist-button add-button"
              onClick={() => setShow(true)}
            >
              Add Project
            </button>
            <button
              type="button"
              className="flex justify-between w-full text-left text-black border-b max-h-16"
              onClick={() => setActive(!active)}
            >
              <div
                className={`pl-5 py-5  w-full ${
                  active
                    ? 'text-white bg-black focus'
                    : 'bg-white black-black hover:bg-black hover:text-white hover:border-white hover:border-x-2 '
                }`}
              >
                Active
              </div>
              <div
                className={`pr-5 text-center py-5 w-full ${
                  active
                    ? 'bg-white black-black hover:bg-black hover:text-white hover:border-white hover:border-x-2 '
                    : 'text-white bg-black focus'
                }`}
              >
                All
              </div>
            </button>
            {currentProjects
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((project) => (
                <button
                  className="workerlist-button"
                  type="button"
                  key={project.id}
                  onClick={() => setProject(project)}
                >
                  {`${project.name}`}
                </button>
              ))}
            <AdminCreateWorkerProjectOrganizationField
              show={show}
              setShow={setShow}
              typeOfField={page === 'user' ? 'worker' : page}
              onSave={projects.refetch}
            />
          </div>
        );
      case 'economy':
        return (
          <div className="workerlist">
            <button type="button" className="workerlist-button display-none">
              {}
            </button>
          </div>
        );
      case 'workerSentiment':
        return (
          <div className="workerlist">
            <button type="button" className="workerlist-button display-none">
              {}
            </button>
          </div>
        );
      case 'organizations':
        return (
          <div className="workerlist">
            '
            <button
              type="button"
              className="focus workerlist-button add-button"
              onClick={() => {
                if (user && user.role === 3) {
                  setShow(true);
                } else {
                  toast('You do not have privileges to add organizations');
                }
              }}
            >
              Add Organization
            </button>
            <button
              type="button"
              className="flex justify-between w-full text-left text-black border-b max-h-16"
              onClick={() => setActive(!active)}
            >
              <div
                className={`pl-5 py-5  w-full ${
                  active
                    ? 'text-white bg-black focus'
                    : 'bg-white black-black hover:bg-black hover:text-white hover:border-white hover:border-x-2 '
                }`}
              >
                Active
              </div>
              <div
                className={`pr-5 text-center py-5 w-full ${
                  active
                    ? 'bg-white black-black hover:bg-black hover:text-white hover:border-white hover:border-x-2 '
                    : 'text-white bg-black focus'
                }`}
              >
                All
              </div>
            </button>
            {currentOrganizations
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((organization) => (
                <button
                  className="workerlist-button"
                  type="button"
                  key={organization.id}
                  onClick={() => setOrganization(organization)}
                >
                  {`${organization.name}`}
                </button>
              ))}
            <AdminCreateWorkerProjectOrganizationField
              show={show}
              setShow={setShow}
              typeOfField={page === 'users' ? 'workers' : page}
              onSave={organizations.refetch}
            />
          </div>
        );
      default:
        return (
          <div className="workerList">
            <button type="button">should not happen</button>
          </div>
        );
    }
  };
  return renderSwitch(page);
}
