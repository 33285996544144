import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { defaultVacationDays, vacationTransferProjectName } from '../../config';
import { createWorkMonthByUser, workMonthByUser } from '../../api/admin';
import { deleteWorkhour, getWorkHoursForMonth, postWorkhour } from '../../api/api';
import { Workhour, Worker, Projects } from '../../types';
import dayjs from 'dayjs';
import { mapProjectNameToRef } from '../../api/apiUtils';
import { useOrganization } from '../context/OrganizationContext';

const getVacationTransferDays = async (
  workMonthId: number,
  projects: Projects | undefined,
  workdayHours: number
): Promise<[number, Array<Workhour>]> => {
  const vacationTransferProject = projects?.find((p) => p.name === vacationTransferProjectName);
  const workHours = await getWorkHoursForMonth(workMonthId);
  const vacationTransferWorkHours = workHours.filter(
    (wh) => vacationTransferProject && wh.projectref === vacationTransferProject.id
  );
  const vacationTransferWorkHoursTotal = vacationTransferWorkHours.reduce(
    (accumulator, workHour) => accumulator + workHour.hours,
    0
  );
  return [vacationTransferWorkHoursTotal / workdayHours, vacationTransferWorkHours];
};

type UseVacationDaysFn = (worker: Worker) => {
  setVacationDays: () => Promise<void>;
  handleVacationTransferSaved: () => Promise<void>;
  vacationDaysTotal: number;
  vacationTransferNextYear: number;
  setVacationTransferNextYear: (value: number) => void;
};

const useVacationDays: UseVacationDaysFn = (worker) => {
  const queryClient = useQueryClient();
  const { organization } = useOrganization();
  const [vacationTransferPreviousYear, setVacationTransferPreviousYear] = useState(0);
  const [vacationTransferNextYear, setVacationTransferNextYear] = useState(0);
  const [vacationDaysTotal, setVacationDaysTotal] = useState(defaultVacationDays);

  const projects = queryClient.getQueryData<Projects>('projects');

  const setVacationDays = useCallback(async (): Promise<void> => {
    const currentYear = new Date().getFullYear();
    const workMonthsCurrentYear = await workMonthByUser(currentYear, 0, worker.id);
    let vacationDays = defaultVacationDays;
    if (workMonthsCurrentYear.length > 0) {
      const workMonth = workMonthsCurrentYear[0];
      const [vacationTransferDaysCurrentYearTotal] = await getVacationTransferDays(
        workMonth.id,
        projects,
        organization!.workdayHours
      );
      setVacationTransferPreviousYear(vacationTransferDaysCurrentYearTotal);
      vacationDays += vacationTransferDaysCurrentYearTotal;
    }
    const workMonthsNextYear = await workMonthByUser(currentYear + 1, 0, worker.id);
    if (workMonthsNextYear.length === 0) {
      setVacationTransferNextYear(0);
    } else {
      const workMonth = workMonthsNextYear[0];
      const [vacationTransferDaysNextYearTotal] = await getVacationTransferDays(
        workMonth.id,
        projects,
        organization!.workdayHours
      );
      setVacationTransferNextYear(vacationTransferDaysNextYearTotal);
      vacationDays -= vacationTransferDaysNextYearTotal;
    }
    setVacationDaysTotal(vacationDays);
  }, [projects, worker]);

  const handleVacationTransferSaved = useCallback(async (): Promise<void> => {
    const nextYear = new Date().getFullYear() + 1;
    const workmonthsDao = await workMonthByUser(nextYear, 0, worker.id);
    let workMonth = workmonthsDao[0];
    if (workmonthsDao.length === 0) {
      const workmonthDaoWrapped = await createWorkMonthByUser(nextYear, 0, worker.id);
      workMonth = workmonthDaoWrapped;
    }
    const [vacationTransferDaysTotal, vacationTransferWorkHours] = await getVacationTransferDays(
      workMonth.id,
      projects,
      organization!.workdayHours
    );
    if (vacationTransferDaysTotal !== vacationTransferNextYear) {
      const dayDifference = vacationTransferNextYear - vacationTransferDaysTotal;
      setVacationDaysTotal(
        defaultVacationDays + vacationTransferPreviousYear - vacationTransferNextYear
      );
      if (dayDifference !== 0) {
        if (0 < vacationTransferNextYear) {
          const vacTransWorkhour: Workhour = {
            id: 0,
            date: dayjs(workMonth.startofmonth),
            hours: vacationTransferNextYear * organization!.workdayHours,
            overtime: false,
            comment: '',
            officeLunch: false,
            projectref: mapProjectNameToRef(vacationTransferProjectName, projects!),
            workmonthref: workMonth.id,
          };
          if (0 < vacationTransferWorkHours.length) {
            vacTransWorkhour.id = vacationTransferWorkHours[0].id;
          }
          postWorkhour(vacTransWorkhour);
        } else if (0 < vacationTransferWorkHours.length) {
          deleteWorkhour(vacationTransferWorkHours[0].id!);
        }
      }
    }
  }, [worker, projects, vacationTransferPreviousYear, vacationTransferNextYear, organization]);

  return {
    setVacationDays,
    handleVacationTransferSaved,
    vacationDaysTotal,
    vacationTransferNextYear,
    setVacationTransferNextYear,
  };
};

export default useVacationDays;
