import createContext from 'constate';
import { Organization } from '../../types';

interface OrganizationContextProps {
  organization: Organization | null;
  setOrganization: React.Dispatch<React.SetStateAction<Organization | null>>;
}

const [OrganizationProvider, useOrganization] = createContext(
  ({ organization, setOrganization }: OrganizationContextProps) => {
    return { organization, setOrganization };
  }
);

export { OrganizationProvider, useOrganization };
