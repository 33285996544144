import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../state/context/UserContext';
import { Worker, Project, Organization } from '../../types';
import AdminEconomyPage from '../components/admin/AdminEconomyPage';
import AdminHeader from '../components/admin/AdminHeader';
import AdminList from '../components/admin/AdminList';
import AdminMobileList from '../components/admin/AdminMobileList';
import AdminNavbar from '../components/admin/AdminNavbar';
import AdminProjectPage from '../components/admin/AdminProjectPage';
import AdminWorkerPage from '../components/admin/AdminWorkerPage';
import AdminWorkerSentiment from '../components/admin/AdminWorkerSentiment';
import SuperAdminOrganizationPage from '../components/admin/SuperAdminOrganizationPage';

//const noLogin = <div>Awaiting login</div>

export default function Admin() {
  const { user } = useUser();
  const [organization, setOrganization] = useState<Organization>();
  const [page, setPage] = useState('users');
  const [worker, setWorker] = useState<Worker>();
  const [project, setProject] = useState<Project>();

  if (user && user.role === 1) {
    return <Navigate to="/dashboard" replace />;
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  const renderSwitch = (p: string): React.ReactNode => {
    switch (p) {
      case 'users':
        return worker ? <AdminWorkerPage worker={worker} /> : null;
      case 'projects':
        return project ? <AdminProjectPage project={project} /> : null;
      case 'economy':
        return <AdminEconomyPage />;
      case 'workerSentiment':
        return <AdminWorkerSentiment />;
      case 'organizations':
        return organization ? <SuperAdminOrganizationPage organization={organization} /> : null;
      default:
        return worker ? <AdminWorkerPage worker={worker} /> : null;
    }
  };

  return (
    <div className="h-screen">
      <AdminHeader />
      <div className="">
        <AdminNavbar currentPage={page} setCurrentPage={setPage} />
        <div className="admin-interface">
          {!['economy', 'workerSentiment'].includes(page) && (
            <>
              <div className="hidden w-64 sm:block">
                <AdminList
                  page={page}
                  setWorker={setWorker}
                  setProject={setProject}
                  setOrganization={setOrganization}
                />
              </div>
              <div className="block sm:hidden">
                <AdminMobileList
                  page={page}
                  setWorker={setWorker}
                  setProject={setProject}
                  setOrganization={setOrganization}
                />
              </div>
            </>
          )}
          {renderSwitch(page)}
        </div>
      </div>
    </div>
  );
}
