import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { MdOutlineClose } from 'react-icons/md';

import { Project, ProjectWorker, Worker, Workers, Projects, Organization } from '../../../types';

import {
  addProjectToWorker,
  deleteProjectWorker,
  getAllProjectWorkers,
  getAllWorkers,
  getWorkYearForWorker,
  updateProjectWorker,
  updateWorker,
} from '../../../api/admin';
import { appendMutation, removeMutation, updateMutation } from '../../../mutations';
import useVacationDays from '../../../state/hooks/useVacationDays';
import { workerHasRegisteredWorkhoursInProject } from '../../../utils/workhour.utils';
import toast from 'react-hot-toast';
import { getOrganizations } from '../../../api/api';

interface AdminWorkerPageProps {
  worker: Worker;
}

export default function AdminWorkerPage({ worker }: AdminWorkerPageProps) {
  const queryClient = useQueryClient();
  const {
    setVacationDays,
    handleVacationTransferSaved,
    vacationDaysTotal,
    vacationTransferNextYear,
    setVacationTransferNextYear,
  } = useVacationDays(worker);
  const [workerState, setWorkerState] = useState(worker);

  const projects = queryClient.getQueryData<Projects>('projects');
  const updateWorkerMutation = updateMutation(updateWorker, 'workers');

  const updateProjectWorkerMutation = updateMutation(updateProjectWorker, 'projectWorkers');
  const deleteMutation = removeMutation(deleteProjectWorker, 'projectWorkers');
  const addProjectToWorkerMutation = appendMutation(addProjectToWorker, 'projectWorkers');
  const projectWorkers = useQuery('projectWorkers', getAllProjectWorkers, {
    initialData: [],
    refetchOnWindowFocus: false,
  });
  const workyear = useQuery(['workyear', worker], () =>
    getWorkYearForWorker(new Date().getFullYear(), worker.id)
  );

  const workers = useQuery<Workers, unknown>('workers', getAllWorkers, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const [departments, setDepartments] = useState(new Set<string>([]));

  const organizations = useQuery<Organization[]>('organizations', getOrganizations, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const [projectRate, setProjectRate] = useState({
    projectId: 0,
    rate: 0,
    worker: 0,
    pwId: 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, identifier: string): void => {
    console.log(identifier, Object.keys(workerState));
    if (identifier === 'validated') {
      setWorkerState({ ...workerState, validated: e.currentTarget.checked });
    } else {
      setWorkerState({
        ...workerState!,
        [`${identifier}`]: e.currentTarget.value === '' ? null : e.currentTarget.value,
      });
    }
  };

  const handleOrganizationChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    identifier: string
  ): void => {
    setWorkerState({
      ...workerState!,
      [`${identifier}`]: e.currentTarget.value,
    });
  };

  const handleDepartmentChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    identifier: string
  ): void => {
    setWorkerState({
      ...workerState!,
      [`${identifier}`]: e.currentTarget.value,
    });
  };

  useEffect(() => {
    if (!workers.isError && !workers.isLoading) {
      setDepartments(new Set(workers.data?.map((worker) => worker.department)));
    }
  }, []);
  const handleButtonClicked = (): void => {
    updateWorkerMutation.mutate(workerState);
  };

  const handleProjectWorkerButtonClicked = (): void => {
    if (projectRate.projectId) {
      updateProjectWorkerMutation.mutate({
        projectId: projectRate.projectId,
        workerId: workerState.id,
        rate: projectRate.rate,
        pwId: projectRate.pwId,
      });
    } else {
      toast.error('No project selected.');
    }
  };

  const handleProjectRemoveButtonClicked = async (entry: ProjectWorker): Promise<void> => {
    // Check whether worker has already registred workhours belonging to project admin wants to delete
    workerHasRegisteredWorkhoursInProject(worker.id, entry.project).then((isTrue) => {
      if (isTrue) {
        toast.error(
          'Cannot delete project from worker as he/she has already registred workhours for this project this month'
        );
      } else {
        deleteMutation.mutate(entry.id);
      }
    });
  };

  const findOtherProjects = (): Array<Project> => {
    const currentProjectIds = projectWorkers.data
      ?.filter((pw) => pw.worker === worker.id)
      .map((pw) => pw.project);
    return projects!
      .filter((p) => !currentProjectIds?.includes(p.id))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const [selectedOption, setSelectedOption] = useState<number>(0);

  const handleProjectAddButtonClicked = (): void => {
    addProjectToWorkerMutation.mutate({ projectId: selectedOption, workerId: worker.id });
    setSelectedOption(findOtherProjects()[0].id);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedOption(parseInt(event.target.value, 10));
  };

  const handleProjectRateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    projectId: number,
    pwId: number
  ): void => {
    setProjectRate({
      ...projectRate,
      rate: parseInt(event.target.value, 10),
      projectId,
      worker: worker.id,
      pwId,
    });
  };

  useEffect(() => {
    setWorkerState(worker);
    findOtherProjects();
    setProjectRate({
      projectId: 0,
      rate: 0,
      worker: 0,
      pwId: 0,
    });
    setVacationDays();
  }, [worker]);

  useEffect(() => {
    setSelectedOption(findOtherProjects()[0].id);
  }, [projectWorkers.data]);

  return (
    <div className="admin-worker-page">
      <h2>{`${workerState.firstname} ${workerState.lastname}`}</h2>
      <div className="flex flex-col items-center projectworker-fields">
        <div>
          <p className="text-black">First name:</p>
          <input
            className="border border-black input border-1"
            type="text"
            value={workerState.firstname}
            onChange={(e) => handleChange(e, 'firstname')}
          />
        </div>
        <div>
          <p className="text-black">Last name:</p>
          <input
            className="border border-black input border-1"
            type="text"
            value={workerState.lastname}
            onChange={(e) => handleChange(e, 'lastname')}
          />
        </div>
        <div>
          <p className="text-black"> Email:</p>
          <input
            className="border border-black input border-1"
            type="email"
            style={{
              textTransform: 'lowercase',
            }}
            value={workerState.email && workerState.email.toLowerCase()}
            onChange={(e) => handleChange(e, 'email')}
          />
        </div>
        <div>
          <p className="text-black">Organization:</p>
          <select
            value={workerState.organization}
            onChange={(e) => handleOrganizationChange(e, 'organization')}
            className="w-64 p-2 border border-black input border-1"
          >
            organizations.data &&{' '}
            {Array.from(organizations.data!).map((organization, i) => (
              <option key={i} value={organization.id}>
                {organization.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className="text-black">Department:</p>
          <select
            value={workerState.department}
            onChange={(e) => handleDepartmentChange(e, 'department')}
            className="w-64 p-2 border border-black input border-1"
          >
            {Array.from(departments).map((department, i) => (
              <option key={i} value={department}>
                {department}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className="text-black">Employment start date:</p>
          <input
            className="border border-black input border-1"
            type="text"
            value={workerState.employmentStartDate || ''}
            placeholder="YYYY-MM-DD"
            onChange={(e) => handleChange(e, 'employmentStartDate')}
          />
        </div>
        <div>
          <p className="text-black">Employment end date:</p>
          <input
            className="border border-black input border-1"
            type="text"
            value={workerState.employmentEndDate || ''}
            placeholder="YYYY-MM-DD"
            onChange={(e) => handleChange(e, 'employmentEndDate')}
          />
        </div>
        <div className="relative">
          <p className="text-black">Work percentage:</p>

          <input
            className="border border-black input border-1"
            type="text"
            value={workerState.workPercentage || ''}
            placeholder="100"
            onChange={(e) => handleChange(e, 'workPercentage')}
          />
          <p className="absolute right-0 w-10 bottom-2">%</p>
        </div>
        <div className="flex py-4">
          <p className="text-black">Validated:</p>
          <input
            className="ml-4 !w-10"
            type="checkbox"
            checked={workerState.validated}
            onChange={(e) => handleChange(e, 'validated')}
          />
        </div>
        <button
          type="button"
          className="mt-1 button move-right"
          onClick={() => handleButtonClicked()}
        >
          Save
        </button>
      </div>
      <div className="w-full projectworker-wrapper">
        <hr className="mb-8 lg:hidden" />
        <div className="sticky top-0 flex justify-between w-full pb-2 bg-white">
          <select
            className="w-full overflow-x-auto border border-black input border-1"
            value={selectedOption}
            onChange={(e) => handleSelectChange(e)}
          >
            {findOtherProjects().map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            className="ml-1 text-xs button"
            onClick={() => handleProjectAddButtonClicked()}
            disabled={selectedOption === 0}
          >
            Add project
          </button>
        </div>
        <div className="h-auto overflow-y-scroll">
          {projectWorkers.data
            ?.filter((entry: ProjectWorker) => entry.worker === worker.id)
            .map((entry: ProjectWorker) => (
              <div className="my-1 project-text" key={entry.id}>
                <MdOutlineClose
                  onClick={() => handleProjectRemoveButtonClicked(entry)}
                  className="remove-project-button"
                />
                {projects?.find((p) => entry.project === p.id)?.name}
                <label htmlFor="rate">
                  Rate:
                  <input
                    name="rate"
                    type="number"
                    className="bg-white border border-black hour-rate-input"
                    defaultValue={entry.rate}
                    onKeyDown={(e) => e.key === 'Enter' && handleProjectWorkerButtonClicked()}
                    onChange={(e) => handleProjectRateChange(e, entry.project, entry.id)}
                  />
                </label>
              </div>
            ))}
        </div>
        <button
          type="button"
          className="float-right mt-1 button"
          onClick={() => handleProjectWorkerButtonClicked()}
        >
          Save rates
        </button>
      </div>
      <div className="px-10 pb-10 vacation-transfer">
        <hr className="mb-8 xl:hidden" />
        <div>
          <p className="text-black">Current flex time:</p>
          <span>
            {`${workyear?.data?.flexYtd || 0} / ${workyear?.data?.flexEom || 0} hr${
              workyear.data?.flexYtd !== 1 && 's'
            }`}
          </span>
        </div>
        <div>
          <p className="text-black">Vacation days used this year:</p>
          <span>{workyear.data?.vacationRegistered}</span>
        </div>
        <div>
          <p className="text-black">Yearly vacation days:</p>
          <span>{vacationDaysTotal}</span>
        </div>
        <div>
          <p className="text-black">Vacation transfer next year:</p>
          <input
            className="border border-black input border-1"
            type="number"
            value={vacationTransferNextYear}
            onKeyDown={(e) => e.key === 'Enter' && handleVacationTransferSaved()}
            onChange={(e) => {
              setVacationTransferNextYear(e.currentTarget.valueAsNumber);
            }}
          />
          <br />
          <button
            type="button"
            className="mt-1 button move-right"
            onClick={() => handleVacationTransferSaved()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
