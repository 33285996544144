import React, { useEffect, useState } from 'react';
import { Organization } from '../../../types';
import { updateMutation } from '../../../mutations';
import { updateOrganization } from '../../../api/admin';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

interface SuperAdminOrganizationPageProps {
  organization: Organization;
}

export default function AdminOrganizationPage({ organization }: SuperAdminOrganizationPageProps) {
  const [organizationState, setOrganizationState] = useState<Organization>(organization);
  const updateOrganizationMutation = updateMutation(updateOrganization, 'organizations');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setOrganizationState({ ...organizationState, name: e.currentTarget.value });
  };

  const handleChangeActiveState = () => {
    setOrganizationState({ ...organizationState, active: !organizationState.active });
  };

  const handleButtonClicked = (): void => {
    updateOrganizationMutation.mutate(organizationState);
  };

  useEffect(() => {
    setOrganizationState(organization);
  }, [organization]);

  return (
    <div className="admin-worker-page">
      {organization && (
        <>
          <h2>{organizationState.name}</h2>
          <div className="organizationworker-fields">
            <div>
              <p className="text-white">Name:</p>
              <input
                className="border border-black input"
                type="text"
                value={organizationState.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="flex items-center justify-start gap-2">
              {organizationState.active ? (
                <MdCheckBox onClick={handleChangeActiveState} size={20} />
              ) : (
                <MdCheckBoxOutlineBlank onClick={handleChangeActiveState} size={20} />
              )}
              <p className="w-full">Active</p>
            </div>
            <button
              type="button"
              className="mt-1 button move-right"
              onClick={() => handleButtonClicked()}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
}
