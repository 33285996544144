import dayjs, { Dayjs } from 'dayjs';
import toast from 'react-hot-toast';
import { getWorkhoursWithWorkmonthref, workMonthByUser } from '../api/admin';
import { mapProjectRefToName } from '../api/apiUtils';
import {
  doctorsReportProjectName,
  hourStepIncrement,
  hourWarningLimit,
  officeLunchProjectName,
  officeLunchProjectRef,
  selfReportProjectName,
  vacationProjectName,
  welfareLeaveSicknessProjectName,
} from '../config';
import {
  MetadataDao,
  Project,
  Projects,
  SelectedDate,
  SelectedWeek,
  Workhour,
  Workhours,
  Workmonth,
} from '../types';
import {
  sicknessRegisteredOnThisDay,
  vacationRegisteredOnThisDay,
  validateNonConflictingProjectsByEntry,
} from './project.utils';
import { groupBy, roundHour } from './utils';

export const projectNameToNewEntryHours = (
  projectName: string,
  hours = -1,
  workdayHours: number
): number => {
  if (-1 === hours) {
    hours = workdayHours;
  }
  switch (projectName) {
    case selfReportProjectName:
      return workdayHours;
    case officeLunchProjectName:
      return 0;
    case vacationProjectName:
      return workdayHours;
    case doctorsReportProjectName:
      return workdayHours;
    default:
      return hours;
  }
};

const workhourStepIncrement: number = parseFloat(
  process.env.WORKHOUR_STEP_INCREMENT || hourStepIncrement.toString()
);

export const getTotalHours = (date: Date, interEntries: Array<Workhour>): number =>
  interEntries
    .filter((entry) => entry.date.date() === date.getDate() && !Number.isNaN(entry.hours))
    .reduce((prev, current) => prev + current.hours, 0);

export const getTotalHoursWeek = (selectedWeek: SelectedWeek, interEntries: Array<Workhour>) =>
  selectedWeek.selectedDates
    .map((selectedDate) =>
      selectedDate.selected ? getTotalHours(selectedDate.date, interEntries) : 0
    )
    .reduce((acc, current) => acc + current, 0);

export const getTotalHoursProject = (project: Project, interEntries: Array<Workhour>): number =>
  interEntries
    .filter((entry) => entry.projectref === project.id)
    .reduce((prev, current) => prev + current.hours, 0);

export const getTotalDays = (entries: Array<Workhour>): Array<number> => {
  const seenDates: Array<number> = [];
  entries.forEach((entry) => {
    if (!seenDates.includes(entry.date.date())) {
      seenDates.push(entry.date.date());
    }
  });
  return seenDates;
};

export const getAllDaysInMonth = (workmonth: Workmonth): Array<Date> => {
  const startDate = dayjs(workmonth.startofmonth);
  const endDate = startDate.endOf('month');

  const dates: Array<Date> = [];
  for (let x = 0; x < endDate.date(); x += 1) {
    dates.push(startDate.add(x, 'day').toDate());
  }
  return dates;
};

export const getAllWeekdays = (workmonth: Workmonth): Array<Date> =>
  getAllDaysInMonth(workmonth).filter((date) => dayjs(date).day() !== 0 && dayjs(date).day() !== 6);

export const filterHolidays = (dates: Array<Date>, holidays: string[]): Array<Date> => {
  return dates.filter((date) => !holidays.includes(dayjs(date).format('YYYY-MM-DD')));
};

export const filterOfficeLunches = (entries: Array<Workhour>): Array<Workhour> =>
  entries.filter((entry) => entry.projectref !== officeLunchProjectRef);

export const getDaysUnaccountedFor = (
  workmonth: Workmonth,
  entries: Array<Workhour>,
  holidays: string[]
): Array<Date> => {
  const allWeekDates = filterHolidays(getAllWeekdays(workmonth), holidays);
  return allWeekDates.filter(
    (date) =>
      !filterOfficeLunches(entries)
        .map((entry) => entry.date.date())
        .includes(dayjs(date).date())
  );
};

export const getOfficeLunchDaysCount = (entries: Array<Workhour>, projects: Projects): number =>
  entries.filter(
    (entry) => mapProjectRefToName(entry.projectref, projects) === officeLunchProjectName
  ).length;

export const getDoctorSickdaysCount = (entries: Array<Workhour>, projects: Projects): number =>
  entries.filter(
    (entry) => mapProjectRefToName(entry.projectref, projects) === doctorsReportProjectName
  ).length;

export const getSelfReportedSickdaysCount = (
  entries: Array<Workhour>,
  projects: Projects
): number =>
  entries.filter(
    (entry) => mapProjectRefToName(entry.projectref, projects) === selfReportProjectName
  ).length;

export const getWelfareLeaveSickdaysCount = (
  entries: Array<Workhour>,
  projects: Projects
): number =>
  entries.filter(
    (entry) => mapProjectRefToName(entry.projectref, projects) === welfareLeaveSicknessProjectName
  ).length;

export const noSicknessRegisteredInMonth = (
  entries: Array<Workhour>,
  projects: Projects
): boolean =>
  getDoctorSickdaysCount(entries, projects) === 0 &&
  getSelfReportedSickdaysCount(entries, projects) === 0 &&
  getWelfareLeaveSickdaysCount(entries, projects) === 0;

export const getVacationdaysCount = (entries: Array<Workhour>, projects: Projects): number =>
  entries.filter((entry) => mapProjectRefToName(entry.projectref, projects) === vacationProjectName)
    .length;

export const getHolidayStyle = (selectedDate: SelectedDate, holidays: string[]): string => {
  const formattedDate = dayjs(selectedDate.date).format('YYYY-MM-DD');
  return holidays.includes(formattedDate) ||
    dayjs(selectedDate.date).day() === 0 ||
    dayjs(selectedDate.date).day() === 6
    ? 'holiday holiday-fg'
    : '';
};

export const getSicknessStyle = (
  selectedDate: SelectedDate,
  entries: Array<Workhour>,
  projects: Projects
): string =>
  sicknessRegisteredOnThisDay(selectedDate.date, entries, projects)
    ? 'bg-gray-spacer border-t-0 border-b-0'
    : '';

export const getVacationStyle = (
  selectedDate: SelectedDate,
  entries: Array<Workhour>,
  projects: Projects
): string =>
  vacationRegisteredOnThisDay(selectedDate.date, entries, projects)
    ? 'bg-gray-spacer border-t-0 border-b-0'
    : '';

export const calculateMissingHours = (
  date: Date,
  interEntries: Array<Workhour>,
  workdayHours: number
): number => {
  return getTotalHours(date, interEntries) < workdayHours
    ? workdayHours - getTotalHours(date, interEntries)
    : workdayHours;
};

export const calculateHoursOnNewEntry = (
  date: Date,
  projectName: string,
  interEntries: Array<Workhour>,
  workdayHours: number
): number => {
  if (projectName !== officeLunchProjectName) {
    return calculateMissingHours(date, interEntries, workdayHours);
  }
  return 0;
};

export const getAllEntriesForSingleDayOfMonth = (
  dayOfMonth: number,
  entries: Array<Workhour>
): Array<Workhour> => {
  const output: Array<Workhour> = [];
  entries.forEach((x) => {
    if (x.date.date() === dayOfMonth) {
      output.push(x);
    }
  });
  return output;
};

/* eslint-disable no-param-reassign */
export const validateDayHoursByGivenEntryOnNew = (
  entry: Workhour,
  selectedWeek: SelectedWeek,
  entries: Array<Workhour>,
  metadata: MetadataDao | undefined,
  projects: Projects
): boolean => {
  let hackHourAmountCheck = true;
  let correctWorkhourIncrement = true;
  let hackConflictingProjectsCheck = true;
  // If undefined, stepincrement is set to environment-variable WORKHOUR_STEP_INCREMENT
  const stepIncrement: number = metadata?.stepIncrement ?? workhourStepIncrement;
  selectedWeek.selectedDates.forEach((x) => {
    if (x.date.getDate() === entry.date.date()) {
      // Validate total amount of hours
      if (getTotalHours(x.date, entries) + entry.hours > 24) {
        hackHourAmountCheck = false;
      }
      // Check if hours follows correct step increment
      if (entry.hours % stepIncrement !== 0 && !Number.isNaN(entry.hours)) {
        correctWorkhourIncrement = false;
      }
      // Validate non-conflicting projects
      if (!validateNonConflictingProjectsByEntry(entry, selectedWeek, entries, projects)) {
        hackConflictingProjectsCheck = false;
      }
    }
  });
  if (!hackHourAmountCheck) {
    toast.error('Total number of daily hours exceeds 24');
    return false;
  }
  if (!hackConflictingProjectsCheck) {
    toast.error('Conflicting projects registered on the same day');
    return false;
  }
  // If not correct workhour-increment, then round to correct and display message
  if (!correctWorkhourIncrement) {
    entry.hours = roundHour(entry.hours, stepIncrement);
    toast.error(`Hour increment should be of ${stepIncrement}. Rounding hour to ${entry.hours}`);
  }
  return true;
};

/* eslint-disable no-param-reassign */
export const validateDayHoursByGivenEntryOnEdit = (
  entry: Workhour,
  entryIndex: number,
  entries: Array<Workhour>,
  selectedWeek: SelectedWeek,
  metadata: MetadataDao | undefined
): boolean => {
  if (entry.projectref === officeLunchProjectRef && entry.hours > 0) {
    toast.error('Office lunch can only be registered as 0 hours');
    return false;
  }
  if (Number.isNaN(entry.hours)) {
    entry.hours = 0;
  }
  const oldEntry = entries[entryIndex];
  let correctWorkhourIncrement = true;
  let hackHourAmountCheck = true;
  // If undefined, stepincrement is set to environment-variable WORKHOUR_STEP_INCREMENT
  const stepIncrement: number = metadata?.stepIncrement ?? workhourStepIncrement;
  selectedWeek.selectedDates.forEach((x) => {
    if (x.date.getDate() === entry.date.date()) {
      if (getTotalHours(x.date, entries) - oldEntry.hours + entry.hours > 24) {
        hackHourAmountCheck = false;
      }
      // Check if hours follows correct step increment
      if (entry.hours % stepIncrement !== 0) {
        correctWorkhourIncrement = false;
      }
    }
  });
  if (!hackHourAmountCheck) {
    toast.error('Total number of daily hours exceeds 24');
    return false;
  }
  // If not correct workhour-increment, then round to correct and display message
  if (!correctWorkhourIncrement) {
    entry.hours = roundHour(entry.hours, stepIncrement);
    toast.error(`Hour increment should be of ${stepIncrement}. Rounding hour to ${entry.hours}`);
  }
  return true;
};

export const attemptHourAmountWarning = (
  selectedWeek: SelectedWeek,
  lastEditedDayOfMonth: number,
  entries: Array<Workhour>
): void => {
  selectedWeek.selectedDates.forEach((x) => {
    if (lastEditedDayOfMonth === x.date.getDate()) {
      if (getTotalHours(x.date, entries) > hourWarningLimit) {
        toast(
          `Warning - Daily work hours exceed ${hourWarningLimit}. Make sure your input is correct.`,
          {
            position: 'top-right',
          }
        );
      }
    }
  });
};

type DayWithHours = {
  day: number;
  hours: number;
};

export const groupHoursByDayOfMonth = (entries: Array<Workhour>): Array<DayWithHours> => {
  let result: Array<DayWithHours> = [];
  entries.forEach((entry) => {
    const currentDay = result.find((i) => i.day === entry.date.date());
    if (!currentDay) {
      result.push({ day: entry.date.date(), hours: entry.hours });
    } else {
      result = result.filter((i) => i.day !== currentDay.day);
      result.push({ ...currentDay, hours: currentDay.hours + entry.hours });
    }
  });
  return result;
};

// Check if projectID is found in the workhours registrered for given month
const projectInWorkhours = (workhours: Workhours, projectID: number): boolean => {
  return workhours.find((workhour) => workhour.projectref === projectID) ? false : true;
};

export const workerHasRegisteredWorkhoursInProject = async (
  worker: number,
  projectID: number
): Promise<boolean> => {
  const date: Dayjs = dayjs();
  const year: number = date.year();
  const month: number = date.month();

  // Get workmonthref for the given worker
  const workmonthref: number | undefined = await workMonthByUser(year, month, worker).then(
    (result) => {
      return result.at(0)?.id;
    }
  );

  if (workmonthref === null || !workmonthref) {
    return false;
  }

  // Get workhours corresponding to given workmonthref
  const workhours: Workhours = await getWorkhoursWithWorkmonthref(workmonthref!);

  if (projectInWorkhours(workhours, projectID)) {
    return false;
  }

  return true;
};

export const getDaysOverTenHours = (entries: Array<Workhour>): Array<number> =>
  groupHoursByDayOfMonth(entries)
    .filter((i) => i.hours > 10)
    .map((i) => i.day);

export const getOvertimeProjectNames = (
  entries: Array<Workhour>,
  projects: Projects
): Array<string> => {
  const groupedByProject = groupBy(entries, (e) => mapProjectRefToName(e.projectref, projects));
  return Object.keys(groupedByProject).reduce<Array<string>>((result, key) => {
    if (groupedByProject[key].find((e) => e.overtime)) {
      result.push(key);
    }
    return result;
  }, []);
};
