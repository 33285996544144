import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { toast } from 'react-hot-toast';
import {
  getAllNonDeliveredWorkers,
  getAllWorkers,
  getAllProjects,
  papaFetch,
} from '../../../api/admin';
import { ReportPostObject, Workers, Workhours } from '../../../types';
import { getWorkerFirstameAndLastname } from '../../../utils/utils';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import Loading from '../Loading';

export default function AdminEconomyPage() {
  const [data, setData] = useState<Workhours>([]);
  const [lateWorkers, setLateWorkers] = useState<Array<string>>([]);
  const [departments, setDepartments] = useState(new Set<string>([]));
  const [defaultReport, setDefaultReport] = useState<ReportPostObject>({
    fromDate: null,
    toDate: null,
    fulltime: null,
    workers: [],
    departments: [],
    projects: [],
    intervalDescriptions: [],
  });

  // Type used for dropdown menus
  type DropdownOption = {
    value: any;
    label: string;
  };

  // Fetch all workers, transform to dropdown options
  // Set departments, and fetch non delivered workers
  const [loadingWorkers, setLoadingWorkers] = useState<boolean>(true);
  const [workerOptions, setWorkerOptions] = useState<Array<DropdownOption>>([]);

  const initWorkers = async () => {
    try {
      const workers = await getAllWorkers();
      setWorkerOptions(
        workers
          .sort((a, b) => (a.firstname > b.firstname ? 1 : -1))
          .map((worker) => ({
            label: `${worker.firstname} ${worker.lastname}`,
            value: worker.id,
          }))
      );
      await getNonDeliveredWorkers(-1, workers);
      setDepartments(new Set(workers.map((worker) => worker.department)));
      return workers;
    } catch {
      throw new Error('Could not fetch workers');
    } finally {
      setLoadingWorkers(false);
    }
  };

  const getNonDeliveredWorkers = async (offset = 0, workers: Workers) => {
    const nonDeliveredWorkers = await getAllNonDeliveredWorkers(offset);
    const nonDeliveredWorkerNames = nonDeliveredWorkers.map((id) =>
      getWorkerFirstameAndLastname(id, workers)
    );
    setLateWorkers(nonDeliveredWorkerNames);
  };

  // Fetch all projects, transform to dropdown options
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [projectOptions, setProjectOptions] = useState<Array<DropdownOption>>([]);

  const initProjects = async () => {
    try {
      const projects = await getAllProjects();
      setProjectOptions(
        projects
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((project) => ({
            label: `${project.name}`,
            value: project.id,
          }))
      );
      return projects;
    } catch {
      throw new Error('Could not fetch projects');
    } finally {
      setLoadingProjects(false);
    }
  };

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      initWorkers();
      initProjects();
    }
  }, [initialized]);

  //Fetches result data and updates it to generate and download csv
  const createCsv = async () => {
    try {
      await papaFetch(defaultReport, setData);
    } catch {
      (e: Error) => {
        console.error(e);
        toast.error(`Report data unavailable at this time`);
      };
    }
  };

  useEffect(() => {
    if (data.length !== 0) {
      const csv = Papa.unparse(data);
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'export.csv');
      tempLink.click();
    }
  }, [data]);

  const handleChange = (e: React.ChangeEvent<any>, type: string): void => {
    switch (type) {
      case 'from':
        setDefaultReport({
          ...defaultReport,
          fromDate: dayjs(e.currentTarget.value).format('YYYY-MM-DD'),
        });
        break;
      case 'to':
        setDefaultReport({
          ...defaultReport,
          toDate: dayjs(e.currentTarget.value).format('YYYY-MM-DD'),
        });
        break;
      case 'workers':
        if (defaultReport.workers) {
          setDefaultReport({
            ...defaultReport,
            workers: [...defaultReport.workers, e.currentTarget.value],
          });
        }
        break;
      case 'departments':
        if (defaultReport.departments) {
          setDefaultReport({
            ...defaultReport,
            departments: [...defaultReport.departments, e.currentTarget.value],
          });
        }
        break;
      case 'projects':
        if (defaultReport.projects) {
          setDefaultReport({
            ...defaultReport,
            projects: [...defaultReport.projects, e.currentTarget.value],
          });
        }
        break;
      default:
        setDefaultReport({ ...defaultReport, [type]: e.currentTarget.value });
        break;
    }
  };

  const handleMultiOptionsChange = (val: MultiValue<DropdownOption>, type: string): void => {
    setDefaultReport({
      ...defaultReport,
      [type]: val.map((v) => v.value),
    });
  };

  const intervalOptions = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Yearly', label: 'Yearly' },
  ];

  const departmentOptions = Array.from(departments).map((role) => {
    return { value: role, label: role || 'Uspesifisert' };
  });

  const pageTable = () => {
    return (
      <div className="admin-worker-page">
        <h2>💰Economy💰</h2>
        <div className="col-span-6 mx-auto">
          <p>From</p>
          <input
            onChange={(e) => handleChange(e, 'from')}
            className="w-64 p-2 border border-black input"
            type="date"
          />
          <p>To</p>
          <input
            onChange={(e) => handleChange(e, 'to')}
            className="w-64 p-2 border border-black input"
            type="date"
          />
          <p>Workers</p>
          <Select
            onChange={(newValue) => handleMultiOptionsChange(newValue, 'workers')}
            className="w-64 p-2 border border-black input"
            options={workerOptions}
            isMulti
          />
          <p>Departments</p>
          <Select
            onChange={(newValue) => handleMultiOptionsChange(newValue, 'departments')}
            className="w-64 p-2 border border-black input"
            options={departmentOptions}
            isMulti
          />
          <div
            className="flex items-center justify-start gap-2 cursor-pointer"
            onClick={() =>
              setDefaultReport({ ...defaultReport, fulltime: !defaultReport.fulltime })
            }
          >
            {defaultReport.fulltime ? (
              <MdCheckBox size={20} />
            ) : (
              <MdCheckBoxOutlineBlank size={20} />
            )}
            <p className="w-full">Only fulltime employees</p>
          </div>
          <p>Projects</p>
          <Select
            onChange={(newValue) => handleMultiOptionsChange(newValue, 'projects')}
            className="w-64 p-2 border border-black input"
            options={projectOptions}
            isMulti
          />
          <p>Interval</p>
          <Select
            onChange={(newValue) => handleMultiOptionsChange(newValue, 'intervalDescriptions')}
            isMulti
            className="w-64 p-2 border border-black input"
            options={intervalOptions}
          />
          <button className="mt-1 button move-right" onClick={createCsv}>
            Download
          </button>
        </div>
        <div className="col-span-6 mx-auto">
          <h3 className="text-2xl">
            {lateWorkers.length > 0 ? 'Have not delivered yet:' : 'Everyone has delivered 🎉'}
          </h3>
          {lateWorkers.map((name, index) => (
            <p key={name + '_' + index}>{name}</p>
          ))}
        </div>
      </div>
    );
  };

  const loadingElement = () => {
    return (
      <div className="flex items-center justify-center w-full">
        <Loading />
      </div>
    );
  };

  return <>{loadingWorkers || loadingProjects ? loadingElement() : pageTable()}</>;
}
