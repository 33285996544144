import createContext from 'constate';
import { useState } from 'react';
import { Workmonth } from '../../types';

const [WorkmonthProvider, useWorkmonth] = createContext(() => {
  const [workmonth, setWorkmonth] = useState<Workmonth | undefined>();
  return { workmonth, setWorkmonth };
});

export { WorkmonthProvider, useWorkmonth };
