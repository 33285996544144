import { Link } from 'react-router-dom';

export default function Header() {
  return (
    /* eslint-disable */
    <div className="w-9/12 text-2xl flex flex-row sm:justify-around text-center flex h-28 mx-auto">
      <img
        src={require('../../../werkz.svg').default}
        className={'w-28 hidden sm:block justify-self-end self-start mt-6 float-right'}
      />
      <p className="version-text text-black mt-6 text-sm hidden sm:block h-1">
        {process.env.REACT_APP_VERSION}
        <br />
        <span className="bg-green-100 p-2 hash-div invisible fixed">
          <span className="text-black">
            Frontend: {process.env.REACT_APP_GIT_SHA ?? 'unknown'} <br />
          </span>
          <span className="text-black">
            Backend: {process.env.REACT_APP_BACKEND_GIT_SHA ?? 'unknown'}
          </span>
        </span>
      </p>

      <div className="flex justify-center sm:justify-end w-full sm:w-9/12 mt-6 space-x-12 text-2xl items-end h-11 text-center text-black ml-auto">
        <div className="flex justify-around space-x-5">
          <Link to="/dashboard" className="button padding-half">
            Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
