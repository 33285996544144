import CsvDownload from 'react-json-to-csv';
import { useQuery } from 'react-query';
import { getWorkerSentiment } from '../../../api/api';
import { WorkerSentiments } from '../../../types';

export default function AdminWorkerSentiment() {
  const { isLoading, isError, data } = useQuery<WorkerSentiments>(
    'workersentiment',
    getWorkerSentiment,
    { staleTime: 60000 }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error...</div>;
  }

  return (
    <div className="grid grid-cols-5 grid-rows-6 mx-auto">
      <h3 className="col-start-2 col-end-5 row-span-1 text-2xl place-self-center">
        Download CSV with WorkerSentiment scores
      </h3>

      {data && (
        <CsvDownload
          className="col-start-3 col-end-5 row-span-1 button"
          data={data}
          filename="WorkerSentiment.csv"
        >
          Download
        </CsvDownload>
      )}
    </div>
  );
}
