import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { MdOutlineClose } from 'react-icons/md';
import { createOrganization, createProject, createWorker } from '../../../api/admin';
import { getOrganizations } from '../../../api/api';
import { Organization, PostWorker } from '../../../types';

interface AdminCreateWorkerFieldProps {
  show: boolean;
  setShow: Function;
  typeOfField: string;
  onSave: VoidFunction;
}

export default function AdminCreateWorkerProjectOrganizationField({
  show,
  setShow,
  typeOfField,
  onSave,
}: AdminCreateWorkerFieldProps) {
  const [worker, setWorker] = useState<PostWorker>({
    firstname: '',
    lastname: '',
    email: '',
    organization: -1,
    validated: true,
  });
  const [project, setProject] = useState({ name: '' });
  const [organization, setOrganization] = useState({ name: '', workdayHours: 8 });
  const availableOrganizations = useQuery<Organization[]>('organizations', getOrganizations, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const onSaveClicked = (): void => {
    if (typeOfField === 'workers') {
      if (
        worker.firstname !== '' &&
        worker.lastname !== '' &&
        worker.email !== '' &&
        worker.organization !== -1
      ) {
        createWorker(worker).then(() => {
          onSave();
          toast.success(`Created ${typeOfField.slice(0, -1)}`);
          setShow(false);
        });
      } else {
        toast.error('No field can be empty');
      }
    } else if (typeOfField === 'projects') {
      // eslint-disable-next-line
      if (project.name !== '') {
        createProject(project).then(() => {
          toast.success(`Created ${typeOfField.slice(0, -1)}`);
          setShow(false);
        });
      } else {
        toast.error('No field can be empty');
      }
    } else {
      // eslint-disable-next-line
      if (organization.name !== '') {
        createOrganization(organization).then(() => {
          toast.success(`Created ${typeOfField.slice(0, -1)}`);
          setShow(false);
        });
      } else {
        toast.error('No field can be empty');
      }
    }
  };

  const listener = (event: KeyboardEvent): void => {
    if (event.code === 'Enter' && show) {
      event.preventDefault();
      onSaveClicked();
    }
    if (event.code === 'Escape') {
      event.preventDefault();
      setShow(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type: string
  ): void => {
    if (typeOfField === 'workers') {
      const value =
        type === 'organization' ? parseInt(e.currentTarget.value) : e.currentTarget.value;
      setWorker({ ...worker, [type]: value });
    } else if (typeOfField === 'projects') {
      setProject({ ...project, [type]: e.currentTarget.value });
    } else {
      setOrganization({ ...organization, [type]: e.currentTarget.value });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [setShow, worker]);

  useEffect(() => {
    if (
      availableOrganizations.data &&
      availableOrganizations.data.length &&
      worker.organization === -1
    ) {
      setWorker({ ...worker, organization: availableOrganizations.data[0].id });
    }
  }, [availableOrganizations]);

  return (
    <div className={`${show ? 'comment-dialog-underlay' : 'hidden'}`}>
      <div className={`${show ? 'comment-dialog-overlay' : 'hidden'}`}>
        <MdOutlineClose className="close-comment-field-icon" onClick={() => setShow(false)} />
        {typeOfField === 'workers' ? (
          <div className="flex flex-col">
            <p>Firstname:</p>
            <input
              type="text"
              value={worker.firstname}
              className="input border border-black"
              onChange={(e) => handleChange(e, 'firstname')}
            />
            <p>Lastname:</p>
            <input
              type="text"
              value={worker.lastname}
              className="input border border-black"
              onChange={(e) => handleChange(e, 'lastname')}
            />
            <p>Email:</p>
            <input
              style={{
                textTransform: 'lowercase',
              }}
              value={worker.email && worker.email.toLowerCase()}
              type="email"
              className="input border border-black"
              onChange={(e) => handleChange(e, 'email')}
            />
            <p>Organization:</p>
            <select
              value={worker.organization}
              className="w-64 p-2 border border-black input border-1"
              onChange={(e) => handleChange(e, 'organization')}
            >
              {availableOrganizations.data!.map((organization, i) => (
                <option key={i} value={organization.id}>
                  {organization.name}
                </option>
              ))}
            </select>
          </div>
        ) : typeOfField === 'projects' ? (
          <div className="flex flex-col">
            <p>Name:</p>
            <input
              type="text"
              value={project.name}
              className="input border border-black"
              onChange={(e) => handleChange(e, 'name')}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <p>Name:</p>
            <input
              type="text"
              value={organization.name}
              className="input border border-black"
              onChange={(e) => handleChange(e, 'name')}
            />
            <p>Workday Hours:</p>
            <input
              type="number"
              value={organization.workdayHours}
              className="input border border-black"
              onChange={(e) => handleChange(e, 'workdayHours')}
            />
          </div>
        )}
        <button type="button" className="button self-end ml-1" onClick={onSaveClicked}>
          Create
        </button>
      </div>
    </div>
  );
}
