import { ReactNode } from 'react';
import { ProjectsProvider } from './context/ProjectsContext';
import { WorkmonthProvider } from './context/WorkmonthContext';
import { WorkyearProvider } from './context/WorkYearContext';

interface DashboardProvidersProps {
  children: ReactNode;
}

export default function DashboardProviders({ children }: DashboardProvidersProps) {
  return (
    <WorkyearProvider>
      <WorkmonthProvider>
        <ProjectsProvider>{children}</ProjectsProvider>
      </WorkmonthProvider>
    </WorkyearProvider>
  );
}
