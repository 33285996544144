import dayjs from 'dayjs';
import { Project, Worker, Workhour } from '../types';

export function groupBy<G>(gs: Array<G>, key: (g: G) => string): { [key: string]: Array<G> } {
  return gs.reduce<{ [key: string]: Array<G> }>((group, g) => {
    const value = key(g);
    group[value] = group[value] ?? [];
    group[value]!.push(g);
    return group;
  }, {});
}

export const removeDuplicates = (data: Array<any>, key: string): Array<Project> =>
  Array.from(new Map(data.map((item) => [item[key], item])).values());

export const removeDuplicatesAndUndefined = (data: Array<any>, key: string): Array<any> =>
  removeDuplicates(data, key).filter((datum) => datum !== undefined);

export const mapEntriesToDates = (entries: Array<Workhour>, offset: number): Array<string> =>
  Array.from(new Set(entries.map((entry) => entry.date.date()))).map((day) =>
    dayjs()
      .startOf('month')
      .add(offset, 'month')
      .add(day - 1, 'day')
      .format('DD-MM-YYYY')
  );

export const getUnknownProjectEntries = (
  entries: Array<Workhour>,
  projects: Array<Project>
): Array<Workhour> => entries.filter((e) => !projects.map((pr) => pr.id).includes(e.projectref));

// Utility function for rounding workhour to correct step increment
export const roundHour = (hour: number, step: number): number => {
  const inv = 1.0 / step;
  return Math.round(hour * inv) / inv;
};

// Due to float precision there is sometimes necessary to explicitly round the values down to 2 decimals
export const roundToMaxTwoDecimalPlaces = (
  value: number | string | undefined
): number | string | undefined => {
  if (value == null) {
    return value;
  }
  const isNumber: boolean = typeof value === 'number';
  let valueCopy: string = value.toString();

  const decimalCount = valueCopy.split('.')[1]?.length || 0;

  if (decimalCount > 2) {
    valueCopy = parseFloat(valueCopy).toFixed(2);
  }

  if (isNumber) {
    return parseFloat(valueCopy);
  }
  return valueCopy;
};

export const aggregateUnknownEntryHours = (
  entries: Array<Workhour>,
  projects: Array<Project>,
  dayOfMonth: number
): number =>
  getUnknownProjectEntries(entries, projects)
    .filter((e) => e.date.date() === dayOfMonth)
    .map((e) => e.hours)
    .reduce((a, b) => a + b, 0);

export const isUnknownProjectEntry = (entry: Workhour, projects: Array<Project>): boolean =>
  !projects.find((p) => p.id === entry.projectref);

export const unknownProject = {
  id: 99999999,
  name: 'Unknown',
  active: true,
};

export const getWorkerFirstameAndLastname = (id: number, workers: Array<Worker>): string => {
  const worker = workers.find((w) => w.id === id);
  if (!worker) return 'Unknown employee';
  return `${worker.firstname} ${worker.lastname}`;
};
